var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    { ref: "ruleForm", attrs: { model: _vm.companyFormData } },
    [
      _c("h1", { staticClass: "sub-module-title" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c("div", { staticClass: "sub-module-block" }, [
        _c(
          "div",
          { staticClass: "div-frac-average-14" },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Company Name",
                  rules: _vm.rules.reqRule,
                  prop: "sysOrganizationId",
                },
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      "value-key": "counterpartySysOrganizationId",
                      disabled:
                        _vm.isAllFieldsDisabled ||
                        _vm.isEdit ||
                        (_vm.isSellerForm &&
                          _vm.termContractType === _vm.TERM_CONTRACT_TYPE.TC) ||
                        (_vm.isSellerForm &&
                          _vm.termContractType === _vm.TERM_CONTRACT_TYPE.COA),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$emit(
                          "getBargesListBySeller",
                          $event,
                          _vm.isSellerForm
                        )
                      },
                    },
                    model: {
                      value: _vm.companyFormData.sysOrganizationId,
                      callback: function ($$v) {
                        _vm.$set(_vm.companyFormData, "sysOrganizationId", $$v)
                      },
                      expression: "companyFormData.sysOrganizationId",
                    },
                  },
                  _vm._l(
                    _vm.sortByAlphabetical(
                      _vm.companyOptionList,
                      "counterpartyCompanyName"
                    ),
                    function (item) {
                      return _c("el-option", {
                        key: item.counterpartySysOrganizationId,
                        attrs: {
                          label: item.counterpartyCompanyName,
                          value: item.counterpartySysOrganizationId,
                          disabled: item.status !== "ACTIVE",
                        },
                      })
                    }
                  ),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "In-Charge",
                  rules: _vm.rules.reqRule,
                  prop: "id",
                },
              },
              [
                _vm.isAllFieldsDisabled
                  ? _c("el-input", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.companyFormData.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.companyFormData, "name", $$v)
                        },
                        expression: "companyFormData.name",
                      },
                    })
                  : _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          "allow-create": "",
                          "value-key": "id",
                          disabled:
                            !_vm.companyFormData.sysOrganizationId ||
                            !_vm.hasEditPermission,
                        },
                        on: { change: _vm.handleInChargeChange },
                        model: {
                          value: _vm.companyFormData.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.companyFormData, "id", $$v)
                          },
                          expression: "companyFormData.id",
                        },
                      },
                      _vm._l(
                        _vm.sortByAlphabetical(_vm.inChargeOptionsList, "name"),
                        function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }
                      ),
                      1
                    ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Email Address",
                  rules: _vm.GENERAL_EMAIL_RULE_REQ,
                  prop: "email",
                },
              },
              [
                _c("el-input", {
                  attrs: {
                    disabled:
                      _vm.isAllFieldsDisabled ||
                      !_vm.hasEditPermission ||
                      !_vm.companyFormData.sysOrganizationId,
                  },
                  model: {
                    value: _vm.companyFormData.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.companyFormData, "email", $$v)
                    },
                    expression: "companyFormData.email",
                  },
                }),
              ],
              1
            ),
            _vm.isShow
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Reference No.", prop: "referenceNo" } },
                  [
                    _c("el-input", {
                      attrs: {
                        disabled:
                          _vm.isAllFieldsDisabled ||
                          !_vm.hasEditPermission ||
                          !_vm.companyFormData.sysOrganizationId,
                      },
                      model: {
                        value: _vm.companyFormData.referenceNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.companyFormData, "referenceNo", $$v)
                        },
                        expression: "companyFormData.referenceNo",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.isShow
          ? _c(
              "div",
              { staticClass: "div-frac-1" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Remark", prop: "remark" } },
                  [
                    _c("el-input", {
                      attrs: {
                        disabled:
                          _vm.isAllFieldsDisabled || !_vm.hasEditPermission,
                        type: "textarea",
                        rows: 1,
                      },
                      model: {
                        value: _vm.companyFormData.remark,
                        callback: function ($$v) {
                          _vm.$set(_vm.companyFormData, "remark", $$v)
                        },
                        expression: "companyFormData.remark",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }